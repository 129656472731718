import logo from "./logo.svg";
import "./App.css";
import "./test";

function App() {
    return (
        <div className="App">
            <div></div>
            <div className="z1">
                <h1 magnetic="">Magnetic Component Demo</h1>

                <button magnetic="">Press me</button>
            </div>
            <div className="footer">MADE BY KRUDI ♥</div>
        </div>
    );
}

export default App;
