import { Magnetic } from "./rk/magnetic";
import { ParticleSystem } from "./rk/particle";

import { $, $$ } from "./rk/utility";

setTimeout(() => {
    $$("[magnetic]").forEach((el) => new Magnetic(el, 10, 0.5));
    let system = new ParticleSystem(
        $("#root"),
        ["🥰", "❓", "😎"],
        20,
        0,
        1000,
        12,
        20
    );
    $("button").addEventListener("click", () => {
        if (system.currentStatus()) {
            system.stop();
        } else {
            system.start();
        }
    });
}, 1000);
